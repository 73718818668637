import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

// Layout components (always loaded)
import Layout from './components/layout/Layout';
import LoadingSpinner from './components/ui/LoadingSpinner';

// Eagerly loaded pages (critical for first impression)
import Home from './pages/Home';

// Lazily loaded pages (loaded on demand)
const AIWebsiteBuilder = lazy(() => import('./pages/Products/AIWebsiteBuilder'));
const CRMGuestJourney = lazy(() => import('./pages/Products/CRMGuestJourney'));
const CaseStudies = lazy(() => import('./pages/CaseStudies'));
const WhyBookingWhizz = lazy(() => import('./pages/WhyBookingWhizz'));
const Contact = lazy(() => import('./pages/Contact'));
const NotFound = lazy(() => import('./pages/NotFound'));

// Features (loaded with main app)
import ChatAssistant from './components/features/ChatAssistant';
import SocialProofWidget from './components/features/SocialProofWidget';
import DemoButton from './components/features/DemoButton';
import HotelierQuestions from './components/features/HotelierQuestions';

const App = () => {
  return (
    <HelmetProvider>
      {/* <Router> */}
        <Layout>
          {/* Top bar with hotelier questions */}
          <HotelierQuestions />
          
          {/* Main content */}
          <Suspense fallback={<LoadingSpinner />}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/products/ai-website-builder" element={<AIWebsiteBuilder />} />
              <Route path="/products/crm-guest-journey" element={<CRMGuestJourney />} />
              <Route path="/case-studies" element={<CaseStudies />} />
              <Route path="/why-bookingwhizz" element={<WhyBookingWhizz />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
          
          {/* Global features */}
          <ChatAssistant />
          <SocialProofWidget />
          <DemoButton />
        </Layout>
      {/* </Router> */}
    </HelmetProvider>
  );
};

export default App;
