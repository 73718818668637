import React from 'react';
import { Link } from 'react-router-dom';

const FeatureHighlight = () => {
  return (
    <section className="py-20 bg-white">
      <div className="container">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
          {/* Feature Content */}
          <div>
            <div className="inline-block px-4 py-2 bg-primary-100 text-primary-700 rounded-full text-sm font-medium mb-4">
              FEATURED TECHNOLOGY
            </div>
            <h2 className="text-3xl md:text-4xl font-bold mb-6">WhatsApp Integration for Seamless Guest Communication</h2>
            <p className="text-lg text-gray-700 mb-8">
              Connect with your guests where they already are. Our exclusive WhatsApp integration puts your hotel in your guests' pockets, enabling seamless communication throughout the entire guest journey.
            </p>
            
            <div className="space-y-6 mb-8">
              <div className="flex items-start">
                <div className="flex-shrink-0 bg-primary-100 rounded-lg p-3 mr-4">
                  <svg className="h-6 w-6 text-primary-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                  </svg>
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2">98% Open Rate</h3>
                  <p className="text-gray-600">WhatsApp messages have a 98% open rate compared to just 20% for email, ensuring your messages are seen.</p>
                </div>
              </div>
              
              <div className="flex items-start">
                <div className="flex-shrink-0 bg-primary-100 rounded-lg p-3 mr-4">
                  <svg className="h-6 w-6 text-primary-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2">Instant Engagement</h3>
                  <p className="text-gray-600">90% of WhatsApp messages are read within 3 seconds, enabling real-time guest communication.</p>
                </div>
              </div>
              
              <div className="flex items-start">
                <div className="flex-shrink-0 bg-primary-100 rounded-lg p-3 mr-4">
                  <svg className="h-6 w-6 text-primary-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                  </svg>
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2">Official Business API</h3>
                  <p className="text-gray-600">Verified business account with green checkmark builds trust and credibility with your guests.</p>
                </div>
              </div>
            </div>
            
            <Link to="/products/crm-guest-journey" className="btn btn-primary">
              Explore WhatsApp Integration
            </Link>
          </div>
          
          {/* Feature Visualization */}
          <div className="relative">
            <div className="bg-gray-100 rounded-3xl p-6 relative z-10">
              {/* WhatsApp Chat Mockup */}
              <div className="bg-white rounded-2xl shadow-soft overflow-hidden">
                {/* Chat Header */}
                <div className="bg-green-500 text-white p-4 flex items-center">
                  <div className="w-10 h-10 bg-white rounded-full flex items-center justify-center mr-3">
                    <span className="text-green-500 font-bold">H</span>
                  </div>
                  <div>
                    <h4 className="font-medium">Luxury Beach Resort</h4>
                    <p className="text-xs opacity-80">Online</p>
                  </div>
                </div>
                
                {/* Chat Messages */}
                <div className="p-4 bg-gray-50 h-80 overflow-y-auto">
                  {/* Hotel Message */}
                  <div className="flex mb-4">
                    <div className="bg-white rounded-lg p-3 shadow-sm max-w-xs">
                      <p className="text-sm">Welcome to Luxury Beach Resort! We're excited for your upcoming stay. Is there anything we can help you with before your arrival?</p>
                      <p className="text-xs text-gray-500 text-right mt-1">10:30 AM</p>
                    </div>
                  </div>
                  
                  {/* Guest Message */}
                  <div className="flex justify-end mb-4">
                    <div className="bg-green-100 rounded-lg p-3 shadow-sm max-w-xs">
                      <p className="text-sm">Hi! I was wondering if I could get an early check-in? My flight arrives at 10am.</p>
                      <p className="text-xs text-gray-500 text-right mt-1">10:32 AM</p>
                    </div>
                  </div>
                  
                  {/* Hotel Message */}
                  <div className="flex mb-4">
                    <div className="bg-white rounded-lg p-3 shadow-sm max-w-xs">
                      <p className="text-sm">We'll do our best to accommodate your early check-in request! I've noted it in your reservation.</p>
                      <p className="text-xs text-gray-500 text-right mt-1">10:33 AM</p>
                    </div>
                  </div>
                  
                  {/* Hotel Message with Upsell */}
                  <div className="flex mb-4">
                    <div className="bg-white rounded-lg p-3 shadow-sm max-w-xs">
                      <p className="text-sm">Would you be interested in upgrading to our Ocean View Suite for just $50 more per night? It includes a private balcony and complimentary breakfast.</p>
                      <div className="mt-2 bg-blue-50 rounded p-2 text-center">
                        <p className="text-sm font-medium text-blue-700">Ocean View Suite Upgrade</p>
                        <p className="text-xs text-blue-600">$50/night</p>
                        <button className="mt-2 bg-blue-600 text-white text-xs py-1 px-3 rounded-full">Accept Upgrade</button>
                      </div>
                      <p className="text-xs text-gray-500 text-right mt-1">10:35 AM</p>
                    </div>
                  </div>
                </div>
                
                {/* Chat Input */}
                <div className="p-3 border-t flex items-center">
                  <div className="bg-gray-100 rounded-full flex-1 flex items-center px-4 py-2">
                    <span className="text-gray-500 text-sm">Type a message...</span>
                  </div>
                  <button className="ml-2 bg-green-500 text-white rounded-full p-2">
                    <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            
            {/* Decorative elements */}
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full -z-10">
              <div className="absolute top-0 right-0 w-40 h-40 bg-green-200 rounded-full opacity-30 -mt-10 -mr-10"></div>
              <div className="absolute bottom-0 left-0 w-60 h-60 bg-primary-200 rounded-full opacity-20 -mb-20 -ml-20"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureHighlight;
