import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ProductsOverview = () => {
  const [activeTab, setActiveTab] = useState('ai-website');
  
  const products = [
    {
      id: 'ai-website',
      name: 'AI Website Builder',
      description: 'Create a stunning, conversion-optimized hotel website in minutes with our AI-powered builder. No coding required.',
      features: [
        'Supports 57 languages for global reach',
        'Instant setup with hotel-specific templates',
        'Built-in booking engine integration',
        'Mobile-first responsive design',
        'SEO optimization out of the box'
      ],
      icon: (
        <svg className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
        </svg>
      ),
      color: 'primary'
    },
    {
      id: 'booking-booster',
      name: 'Booking Booster',
      description: 'Increase direct bookings with smart conversion tools that create urgency and showcase value to potential guests.',
      features: [
        'Dynamic popups with personalized offers',
        'Social proof notifications',
        'Smart price comparison with OTAs',
        'Abandoned booking recovery',
        'Customizable urgency messaging'
      ],
      icon: (
        <svg className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
        </svg>
      ),
      color: 'secondary'
    },
    {
      id: 'crm',
      name: 'CRM & Guest Journey',
      description: 'Manage the complete guest journey from pre-stay to post-stay with our comprehensive CRM system.',
      features: [
        'Unified guest profiles across all touchpoints',
        'Automated pre-stay and post-stay communications',
        'WhatsApp integration for seamless messaging',
        'Guest preference tracking',
        'Sentiment analysis from reviews'
      ],
      icon: (
        <svg className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
        </svg>
      ),
      color: 'accent'
    },
    {
      id: 'loyalty',
      name: 'Loyalty Program',
      description: 'Build lasting guest relationships with a flexible, branded loyalty system that keeps guests coming back.',
      features: [
        'Tier-based membership structure',
        'Customizable rewards and redemption options',
        'Gamification elements for engagement',
        'Points for both stays and non-stay activities',
        'Integration with booking engine and PMS'
      ],
      icon: (
        <svg className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      ),
      color: 'primary'
    },
    {
      id: 'upselling',
      name: 'Upselling',
      description: 'Increase revenue with AI-powered upselling that offers the right upgrades at the perfect moment.',
      features: [
        'Email and WhatsApp upgrade offers',
        'PMS integration for real-time inventory',
        'AI-powered timing optimization',
        'Personalized recommendations',
        'One-click purchasing for guests'
      ],
      icon: (
        <svg className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
        </svg>
      ),
      color: 'secondary'
    },
    {
      id: 'rate-match',
      name: 'AI-Powered Rate Match',
      description: 'Drive more direct bookings by automatically matching or beating OTA prices while highlighting exclusive benefits.',
      features: [
        'Real-time OTA price monitoring',
        'Automatic rate adjustments',
        'Direct booking incentives display',
        'Price comparison widget',
        'Customizable pricing rules'
      ],
      icon: (
        <svg className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
        </svg>
      ),
      color: 'accent'
    }
  ];

  const activeProduct = products.find(product => product.id === activeTab);

  return (
    <section className="py-20 bg-gray-50">
      <div className="container">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">One Platform, Complete Hotel Control</h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Our integrated modules work together seamlessly to optimize your entire guest journey and maximize revenue.
          </p>
        </div>

        {/* Product Tabs */}
        <div className="flex flex-wrap justify-center mb-12">
          <div className="bg-white rounded-full shadow-soft p-2 flex flex-wrap justify-center">
            {products.map((product) => (
              <button
                key={product.id}
                className={`px-4 py-2 rounded-full text-sm md:text-base font-medium transition-all duration-200 mx-1 my-1 ${
                  activeTab === product.id
                    ? `bg-${product.color}-100 text-${product.color}-700`
                    : 'text-gray-600 hover:bg-gray-100'
                }`}
                onClick={() => setActiveTab(product.id)}
              >
                {product.name}
              </button>
            ))}
          </div>
        </div>

        {/* Active Product Content */}
        {activeProduct && (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            {/* Product Info */}
            <div className={`text-${activeProduct.color}-600`}>
              <div className="mb-6">{activeProduct.icon}</div>
              <h3 className="text-3xl font-bold text-gray-900 mb-4">{activeProduct.name}</h3>
              <p className="text-lg text-gray-700 mb-6">{activeProduct.description}</p>
              
              <ul className="space-y-3 mb-8">
                {activeProduct.features.map((feature, index) => (
                  <li key={index} className="flex items-start">
                    <svg className={`h-6 w-6 text-${activeProduct.color}-500 mr-2 flex-shrink-0`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="text-gray-700">{feature}</span>
                  </li>
                ))}
              </ul>
              
              <Link 
                to={`/products/${activeProduct.id}`} 
                className={`btn bg-${activeProduct.color}-600 hover:bg-${activeProduct.color}-700 text-white`}
              >
                Learn More
              </Link>
            </div>
            
            {/* Product Visualization */}
            <div className="bg-white rounded-2xl shadow-soft-lg p-6">
              <div className={`bg-${activeProduct.color}-50 rounded-xl aspect-w-4 aspect-h-3 flex items-center justify-center p-8`}>
                {/* This would be replaced with actual product screenshots or illustrations */}
                <div className={`text-${activeProduct.color}-500 text-center`}>
                  <div className={`bg-${activeProduct.color}-100 inline-flex rounded-full p-4 mb-4`}>
                    {activeProduct.icon}
                  </div>
                  <h4 className="text-xl font-semibold mb-2">{activeProduct.name}</h4>
                  <p className="text-gray-600">Product visualization placeholder</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default ProductsOverview;
