import React from 'react';

// Import section components
import Hero from '../components/sections/Hero';
import PartnerLogos from '../components/sections/PartnerLogos';
import ProductsOverview from '../components/sections/ProductsOverview';
import FeatureHighlight from '../components/sections/FeatureHighlight';
import CaseStudies from '../components/sections/CaseStudies';
import CTASection from '../components/sections/CTASection';

const Home = () => {
  return (
    <div>
      <Hero />
      <PartnerLogos />
      <ProductsOverview />
      <FeatureHighlight />
      <CaseStudies />
      <CTASection />
    </div>
  );
};

export default Home;
