import React from 'react';
import { Link } from 'react-router-dom';

const Button = ({ 
  children, 
  variant = 'primary', 
  size = 'md', 
  className = '', 
  as = 'button',
  ...props 
}) => {
  const baseClasses = 'inline-flex items-center justify-center rounded-2xl font-medium transition-all duration-200 shadow-soft';
  
  const variantClasses = {
    primary: 'bg-primary-500 text-secondary-600 hover:bg-primary-600',
    secondary: 'bg-secondary-600 text-white hover:bg-secondary-700',
    accent: 'bg-primary-500 text-secondary-600 hover:bg-primary-600',
    outline: 'border-2 border-primary-500 text-primary-500 hover:bg-primary-50',
    white: 'bg-white text-primary-500 hover:bg-gray-100',
    transparent: 'bg-transparent border-2 border-white text-white hover:bg-white hover:bg-opacity-10'
  };
  
  const sizeClasses = {
    sm: 'px-4 py-2 text-sm',
    md: 'px-6 py-3',
    lg: 'px-8 py-4 text-lg'
  };
  
  const classes = `${baseClasses} ${variantClasses[variant]} ${sizeClasses[size]} ${className}`;
  
  const Component = as === 'link' ? Link : as;
  
  return (
    <Component className={classes} {...props}>
      {children}
    </Component>
  );
};

export default Button;
