import React from 'react';
import { Link } from 'react-router-dom';

const HotelierQuestions = () => {
  const questions = [
    "How can I increase direct bookings?",
    "What's the ROI of a loyalty program?",
    "How to personalize guest communications?",
    "How can I compete with OTAs?",
    "What's the best way to upsell room upgrades?"
  ];
  
  const [activeQuestion, setActiveQuestion] = React.useState(null);
  
  const handleQuestionClick = (index) => {
    setActiveQuestion(index === activeQuestion ? null : index);
  };
  
  return (
    <div className="bg-primary-600 text-secondary-600 py-2 px-4 text-center text-sm">
      <div className="container flex justify-between items-center">
        <span className="hidden md:inline">Ask like a hotelier:</span>
        <div className="flex-1 flex justify-center space-x-4 overflow-x-auto">
          {questions.map((question, index) => (
            <button 
              key={index}
              className={`hover:underline whitespace-nowrap ${index > 1 ? 'hidden md:block' : ''} ${index > 2 ? 'hidden lg:block' : ''}`}
              onClick={() => handleQuestionClick(index)}
            >
              {question}
            </button>
          ))}
        </div>
        <button className="hidden md:block text-xs border border-secondary-600 rounded-full px-2 py-1 hover:bg-primary-400">
          More Questions
        </button>
      </div>
      
      {activeQuestion !== null && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white text-secondary-600 rounded-2xl shadow-lg w-full max-w-2xl animate-fade-in">
            <div className="flex justify-between items-center p-6 border-b">
              <h3 className="text-xl font-bold text-secondary-600">{questions[activeQuestion]}</h3>
              <button 
                onClick={() => setActiveQuestion(null)}
                className="text-tertiary-500 hover:text-secondary-600"
              >
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className="p-6">
              {activeQuestion === 0 && (
                <div>
                  <p className="mb-4">To increase direct bookings for your hotel, consider these proven strategies:</p>
                  <ul className="space-y-2 mb-6">
                    <li className="flex items-start">
                      <svg className="h-5 w-5 text-primary-500 mr-2 flex-shrink-0 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      <span><strong>Optimize your website</strong> - Create a fast, mobile-friendly website with clear booking CTAs and compelling visuals.</span>
                    </li>
                    <li className="flex items-start">
                      <svg className="h-5 w-5 text-primary-500 mr-2 flex-shrink-0 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      <span><strong>Price match guarantee</strong> - Offer to match or beat OTA prices to encourage direct bookings.</span>
                    </li>
                    <li className="flex items-start">
                      <svg className="h-5 w-5 text-primary-500 mr-2 flex-shrink-0 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      <span><strong>Exclusive benefits</strong> - Offer perks that are only available for direct bookings (room upgrades, late checkout, etc.).</span>
                    </li>
                    <li className="flex items-start">
                      <svg className="h-5 w-5 text-primary-500 mr-2 flex-shrink-0 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      <span><strong>Loyalty program</strong> - Implement a rewards program that incentivizes repeat direct bookings.</span>
                    </li>
                    <li className="flex items-start">
                      <svg className="h-5 w-5 text-primary-500 mr-2 flex-shrink-0 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      <span><strong>Personalized communication</strong> - Use WhatsApp and email to build direct relationships with guests.</span>
                    </li>
                  </ul>
                  <p className="mb-4">BookingWhizz's suite of tools can help you implement all these strategies with our AI Website Builder, Rate Match technology, CRM, and Loyalty Program solutions.</p>
                  <div className="flex justify-end mt-6">
                    <button className="btn btn-primary">
                      Learn More About Our Solutions
                    </button>
                  </div>
                </div>
              )}
              
              {activeQuestion === 1 && (
                <div>
                  <p className="mb-4">A well-implemented hotel loyalty program typically delivers:</p>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
                    <div className="bg-white rounded-2xl shadow-soft p-6 text-center">
                      <div className="text-3xl font-bold text-primary-500 mb-2">22%</div>
                      <p className="text-sm text-tertiary-500">Increase in repeat bookings</p>
                    </div>
                    <div className="bg-white rounded-2xl shadow-soft p-6 text-center">
                      <div className="text-3xl font-bold text-primary-500 mb-2">15%</div>
                      <p className="text-sm text-tertiary-500">Higher average spend per stay</p>
                    </div>
                    <div className="bg-white rounded-2xl shadow-soft p-6 text-center">
                      <div className="text-3xl font-bold text-primary-500 mb-2">3.2x</div>
                      <p className="text-sm text-tertiary-500">ROI in the first year</p>
                    </div>
                  </div>
                  <p className="mb-4">The BookingWhizz Loyalty Program is fully customizable with tier-based rewards that keep guests coming back. Our clients typically see these benefits within 6-12 months of implementation.</p>
                  <div className="flex justify-end mt-6">
                    <button className="btn btn-primary">
                      Explore Our Loyalty Program
                    </button>
                  </div>
                </div>
              )}
              
              {activeQuestion === 2 && (
                <div>
                  <p className="mb-4">Personalizing guest communications is essential for building loyalty and increasing revenue. Here's how to do it effectively:</p>
                  <ul className="space-y-2 mb-6">
                    <li className="flex items-start">
                      <svg className="h-5 w-5 text-primary-500 mr-2 flex-shrink-0 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      <span><strong>Collect and utilize guest data</strong> - Build comprehensive profiles with preferences, stay history, and behavior.</span>
                    </li>
                    <li className="flex items-start">
                      <svg className="h-5 w-5 text-primary-500 mr-2 flex-shrink-0 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      <span><strong>Segment your audience</strong> - Group guests by behavior, preferences, or demographics for targeted messaging.</span>
                    </li>
                    <li className="flex items-start">
                      <svg className="h-5 w-5 text-primary-500 mr-2 flex-shrink-0 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      <span><strong>Use the right channels</strong> - Communicate via guests' preferred platforms (WhatsApp, email, SMS).</span>
                    </li>
                    <li className="flex items-start">
                      <svg className="h-5 w-5 text-primary-500 mr-2 flex-shrink-0 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      <span><strong>Automate with intelligence</strong> - Set up automated journeys that respond to guest behavior.</span>
                    </li>
                  </ul>
                  <p className="mb-4">BookingWhizz's CRM & Guest Engagement solution helps you implement all these strategies with our unified guest profiles, WhatsApp integration, and automated journey tools.</p>
                  <div className="flex justify-end mt-6">
                    <button className="btn btn-primary">
                      Discover Our CRM Solution
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HotelierQuestions;
