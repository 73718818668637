import React from 'react';
import { Link } from 'react-router-dom';

const CTASection = ({ 
  title = "Ready to transform your hotel's digital presence?", 
  description = "Join hundreds of hotels worldwide that trust BookingWhizz to increase direct bookings and enhance guest experiences.",
  buttonText = "Get Started",
  buttonLink = "/contact",
  variant = "primary"
}) => {
  const bgClass = variant === 'primary' ? 'bg-primary-500' : 'bg-white';
  const textClass = variant === 'primary' ? 'text-secondary-600' : 'text-secondary-600';
  const buttonVariant = variant === 'primary' ? 'secondary' : 'primary';
  
  return (
    <section className={`py-16 ${bgClass}`}>
      <div className="container">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className={`text-3xl md:text-4xl font-bold mb-4 ${textClass}`}>{title}</h2>
          <p className={`text-lg mb-8 ${variant === 'primary' ? 'text-secondary-600' : 'text-tertiary-500'}`}>
            {description}
          </p>
          <Link to={buttonLink} className={`btn btn-${buttonVariant} text-lg px-8 py-4`}>
            {buttonText}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default CTASection;
