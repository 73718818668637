import React from 'react';
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <section className="bg-gradient-to-br from-primary-50 to-secondary-50 pt-16 pb-24 md:pt-24 md:pb-32">
      <div className="container">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          {/* Hero Content */}
          <div className="text-center lg:text-left">
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-gray-900 mb-6 leading-tight">
              Revolutionize Your <span className="text-primary-600">Hotel's Digital Experience</span>
            </h1>
            <p className="text-xl text-gray-700 mb-8 max-w-2xl mx-auto lg:mx-0">
              The all-in-one hotel technology suite that drives direct bookings, enhances guest loyalty, and maximizes revenue through every stage of the guest journey.
            </p>
            <div className="flex flex-col sm:flex-row justify-center lg:justify-start space-y-4 sm:space-y-0 sm:space-x-4">
              <Link to="/contact" className="btn btn-primary text-lg px-8 py-4">
                Get Your Demo
              </Link>
              <Link to="/products/ai-website-builder" className="btn btn-outline text-lg px-8 py-4">
                Free Trial
              </Link>
            </div>
          </div>

          {/* Hero Animation/Image */}
          <div className="relative">
            <div className="bg-white rounded-3xl shadow-soft-lg p-6 relative z-10">
              <div className="aspect-w-16 aspect-h-9 bg-gray-100 rounded-xl mb-6 overflow-hidden">
                {/* This would be replaced with an actual video or animation */}
                <div className="flex items-center justify-center h-full bg-primary-100 text-primary-600">
                  <svg className="h-20 w-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div className="bg-primary-50 rounded-xl p-4 text-center">
                  <div className="text-primary-600 mb-2">
                    <svg className="h-8 w-8 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                  </div>
                  <h3 className="text-sm font-semibold">AI Website</h3>
                </div>
                <div className="bg-secondary-50 rounded-xl p-4 text-center">
                  <div className="text-secondary-600 mb-2">
                    <svg className="h-8 w-8 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
                    </svg>
                  </div>
                  <h3 className="text-sm font-semibold">CRM</h3>
                </div>
                <div className="bg-accent-50 rounded-xl p-4 text-center">
                  <div className="text-accent-600 mb-2">
                    <svg className="h-8 w-8 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <h3 className="text-sm font-semibold">Upselling</h3>
                </div>
              </div>
            </div>
            
            {/* Decorative elements */}
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full -z-10">
              <div className="absolute top-0 right-0 w-64 h-64 bg-primary-200 rounded-full opacity-20 -mt-20 -mr-20"></div>
              <div className="absolute bottom-0 left-0 w-40 h-40 bg-secondary-200 rounded-full opacity-20 -mb-10 -ml-10"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
