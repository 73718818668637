import React from 'react';
import { Link } from 'react-router-dom';

const CaseStudies = () => {
  const caseStudies = [
    {
      id: 1,
      title: 'MGM Muthu Hotels',
      description: 'How MGM Muthu increased direct bookings by 43% with BookingWhizz CRM & WhatsApp integration',
      image: 'case-study-mgm.jpg',
      stats: [
        { value: '43%', label: 'Increase in direct bookings' },
        { value: '2.8x', label: 'ROI in first 6 months' },
        { value: '28%', label: 'Increase in guest satisfaction' }
      ],
      category: 'Hotel Group',
      link: '/case-studies/mgm-muthu'
    },
    {
      id: 2,
      title: 'Pramana Hotels & Resorts',
      description: 'Pramana implemented BookingWhizz loyalty program to achieve 52% repeat booking rate',
      image: 'case-study-pramana.jpg',
      stats: [
        { value: '52%', label: 'Repeat booking rate' },
        { value: '68%', label: 'Loyalty program enrollment' },
        { value: '€124K', label: 'Additional annual revenue' }
      ],
      category: 'Luxury Resort',
      link: '/case-studies/pramana-resorts'
    },
    {
      id: 3,
      title: 'VisitMyCity Program',
      description: 'How a citywide hotel program used BookingWhizz to compete with OTAs and increase tourism',
      image: 'case-study-visitmycity.jpg',
      stats: [
        { value: '37%', label: 'Shift from OTA to direct' },
        { value: '12K+', label: 'New loyalty members' },
        { value: '€1.2M', label: 'Saved in OTA commissions' }
      ],
      category: 'Citywide Program',
      link: '/case-studies/visitmycity'
    }
  ];

  return (
    <section className="py-20 bg-gray-50">
      <div className="container">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">Success Stories</h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            See how hotels and resorts around the world are transforming their guest experience and increasing revenue with BookingWhizz.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {caseStudies.map((study) => (
            <div key={study.id} className="bg-white rounded-2xl shadow-soft overflow-hidden transition-transform duration-300 hover:-translate-y-2">
              {/* Case Study Image */}
              <div className="h-48 bg-gray-200 relative">
                {/* This would be replaced with an actual image */}
                <div className="absolute inset-0 flex items-center justify-center bg-primary-100 text-primary-600">
                  <span className="font-medium">{study.title} Image</span>
                </div>
                <div className="absolute top-4 left-4 bg-white text-primary-600 text-xs font-bold px-3 py-1 rounded-full">
                  {study.category}
                </div>
              </div>
              
              {/* Case Study Content */}
              <div className="p-6">
                <h3 className="text-xl font-bold mb-2">{study.title}</h3>
                <p className="text-gray-600 mb-6">{study.description}</p>
                
                {/* Stats */}
                <div className="grid grid-cols-3 gap-2 mb-6">
                  {study.stats.map((stat, index) => (
                    <div key={index} className="text-center">
                      <div className="text-2xl font-bold text-primary-600">{stat.value}</div>
                      <div className="text-xs text-gray-500">{stat.label}</div>
                    </div>
                  ))}
                </div>
                
                <Link to={study.link} className="text-primary-600 font-medium flex items-center hover:text-primary-700">
                  Read Full Case Study
                  <svg className="ml-2 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </Link>
              </div>
            </div>
          ))}
        </div>
        
        <div className="text-center mt-12">
          <Link to="/case-studies" className="btn btn-outline">
            View All Case Studies
          </Link>
        </div>
      </div>
    </section>
  );
};

export default CaseStudies;
