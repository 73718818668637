import React from 'react';

const Testimonial = ({ 
  quote, 
  author, 
  role, 
  company, 
  image,
  className = '' 
}) => {
  return (
    <div className={`bg-white rounded-2xl shadow-soft p-8 ${className}`}>
      <div className="flex flex-col h-full">
        <div className="mb-6">
          <svg className="h-8 w-8 text-primary-500 mb-4" fill="currentColor" viewBox="0 0 24 24">
            <path d="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.983v10h-9.983z" />
          </svg>
          <p className="text-secondary-600 text-lg italic">{quote}</p>
        </div>
        <div className="mt-auto flex items-center">
          {image && (
            <div className="mr-4 flex-shrink-0">
              <img 
                src={image} 
                alt={author} 
                className="h-12 w-12 rounded-full object-cover border-2 border-primary-500" 
              />
            </div>
          )}
          <div>
            <h4 className="text-secondary-600 font-semibold">{author}</h4>
            <p className="text-tertiary-500 text-sm">
              {role}{company && `, ${company}`}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
