import React, { useState, useEffect } from 'react';

const SocialProofWidget = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [currentNotification, setCurrentNotification] = useState(null);
  
  const notifications = [
    {
      name: 'Hotel Bellevue',
      action: 'just upgraded their CRM',
      location: 'Paris, France',
      time: '2 minutes ago'
    },
    {
      name: 'Sunset Resort',
      action: 'booked a demo',
      location: 'Bali, Indonesia',
      time: '5 minutes ago'
    },
    {
      name: 'Mountain View Lodge',
      action: 'increased direct bookings by 32%',
      location: 'Aspen, USA',
      time: '12 minutes ago'
    },
    {
      name: 'Oceanfront Suites',
      action: 'launched their new website',
      location: 'Sydney, Australia',
      time: '18 minutes ago'
    },
    {
      name: 'City Central Hotel',
      action: 'activated WhatsApp integration',
      location: 'London, UK',
      time: '25 minutes ago'
    }
  ];
  
  useEffect(() => {
    // Show first notification after 5 seconds
    const initialTimer = setTimeout(() => {
      showNotification();
    }, 5000);
    
    return () => clearTimeout(initialTimer);
  }, []);
  
  const showNotification = () => {
    // Select random notification
    const randomIndex = Math.floor(Math.random() * notifications.length);
    setCurrentNotification(notifications[randomIndex]);
    setIsVisible(true);
    
    // Hide after 5 seconds
    const hideTimer = setTimeout(() => {
      setIsVisible(false);
      
      // Schedule next notification after hiding
      const nextTimer = setTimeout(() => {
        showNotification();
      }, Math.random() * 20000 + 15000); // Random time between 15-35 seconds
      
      return () => clearTimeout(nextTimer);
    }, 5000);
    
    return () => clearTimeout(hideTimer);
  };
  
  if (!isVisible || !currentNotification) return null;
  
  return (
    <div className="fixed bottom-6 left-6 max-w-xs bg-white rounded-lg shadow-lg p-4 z-40 animate-slide-up">
      <div className="flex items-start">
        <div className="flex-shrink-0 bg-primary-100 rounded-full p-2 mr-3">
          <svg className="h-6 w-6 text-primary-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
          </svg>
        </div>
        <div>
          <p className="text-sm font-medium text-gray-900">
            {currentNotification.name} {currentNotification.action}
          </p>
          <p className="mt-1 text-xs text-gray-500">
            {currentNotification.location} • {currentNotification.time}
          </p>
        </div>
        <button 
          onClick={() => setIsVisible(false)}
          className="ml-4 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Close</span>
          <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default SocialProofWidget;
