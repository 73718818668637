import React from 'react';

const PartnerLogos = () => {
  // In a real implementation, these would be actual logo images
  const partners = [
    { name: 'SITA Egypt', logo: 'partner-sita.png' },
    { name: 'Hotelogix', logo: 'partner-hotelogix.png' },
    { name: 'Opera Cloud', logo: 'partner-opera.png' },
    { name: 'Mews', logo: 'partner-mews.png' },
    { name: 'Cloudbeds', logo: 'partner-cloudbeds.png' },
    { name: 'Mautic', logo: 'partner-mautic.png' }
  ];

  return (
    <section className="py-12 bg-white">
      <div className="container">
        <div className="text-center mb-8">
          <p className="text-gray-600 font-medium">Trusted by 1300+ hotels across 170 cities worldwide</p>
        </div>
        
        <div className="flex flex-wrap justify-center items-center gap-8 md:gap-12">
          {partners.map((partner, index) => (
            <div key={index} className="flex items-center justify-center">
              {/* Placeholder for actual logo images */}
              <div className="h-12 w-32 bg-gray-100 rounded flex items-center justify-center text-gray-500 font-medium">
                {partner.name}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PartnerLogos;
