import React, { useState } from 'react';
import { XIcon } from '@heroicons/react/outline';

const ChatAssistant = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([
    {
      sender: 'bot',
      text: 'Hi there! I\'m BookingWhizz Assistant. How can I help you today?',
      time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    }
  ]);
  const [inputText, setInputText] = useState('');

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    
    if (!inputText.trim()) return;
    
    // Add user message
    const userMessage = {
      sender: 'user',
      text: inputText,
      time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    };
    
    setMessages([...messages, userMessage]);
    setInputText('');
    
    // Simulate bot response after a short delay
    setTimeout(() => {
      const botResponses = [
        "I'd be happy to tell you more about our AI Website Builder. It allows you to create a stunning hotel website in minutes with 57 language support.",
        "Our CRM & Guest Engagement solution helps you build lasting relationships with your guests through personalized communication.",
        "The BookingWhizz Loyalty Program is fully customizable with tier-based rewards that keep guests coming back.",
        "I can help you schedule a demo with one of our specialists. Would you like me to arrange that for you?",
        "Our pricing is customized based on your hotel's specific needs. I can connect you with our sales team to discuss options."
      ];
      
      const randomResponse = botResponses[Math.floor(Math.random() * botResponses.length)];
      
      const botMessage = {
        sender: 'bot',
        text: randomResponse,
        time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      };
      
      setMessages(prevMessages => [...prevMessages, botMessage]);
    }, 1000);
  };

  const suggestedQuestions = [
    "How does the AI Website Builder work?",
    "Tell me about WhatsApp integration",
    "What's the ROI of your solutions?",
    "How much does BookingWhizz cost?",
    "Can I get a demo?"
  ];

  const handleSuggestedQuestion = (question) => {
    // Add user message
    const userMessage = {
      sender: 'user',
      text: question,
      time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    };
    
    setMessages([...messages, userMessage]);
    
    // Simulate bot response after a short delay
    setTimeout(() => {
      let botResponse = "";
      
      switch(question) {
        case "How does the AI Website Builder work?":
          botResponse = "Our AI Website Builder creates a stunning hotel website in 3 simple steps: 1) Input your hotel details, 2) Choose a template, and 3) Publish your site. It supports 57 languages and includes built-in booking engine integration.";
          break;
        case "Tell me about WhatsApp integration":
          botResponse = "Our WhatsApp integration connects your hotel with guests on their preferred platform. It offers 98% open rates, instant engagement, and uses the official WhatsApp Business API for verified status.";
          break;
        case "What's the ROI of your solutions?":
          botResponse = "Our clients typically see a 3.5x ROI in the first year. This includes a 30% average increase in direct bookings, 42% increase in upsell revenue, and significant savings on OTA commissions.";
          break;
        case "How much does BookingWhizz cost?":
          botResponse = "Our pricing is customized based on your hotel's specific needs and size. We offer flexible plans starting from $99/month. I'd be happy to connect you with our sales team for a personalized quote.";
          break;
        case "Can I get a demo?":
          botResponse = "Absolutely! We offer personalized demos to show you how BookingWhizz can work for your specific hotel needs. Would you like me to arrange a demo for you?";
          break;
        default:
          botResponse = "I'd be happy to help with that. Could you provide more details about what you're looking for?";
      }
      
      const botMessage = {
        sender: 'bot',
        text: botResponse,
        time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      };
      
      setMessages(prevMessages => [...prevMessages, botMessage]);
    }, 1000);
  };

  return (
    <>
      {/* Chat Button */}
      <button
        onClick={toggleChat}
        className="fixed bottom-6 right-6 bg-primary-600 text-white rounded-full p-4 shadow-lg hover:bg-primary-700 transition-all z-50"
        aria-label="Chat with BookingWhizz Assistant"
      >
        {isOpen ? (
          <XIcon className="h-6 w-6" />
        ) : (
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
            />
          </svg>
        )}
      </button>

      {/* Chat Window */}
      {isOpen && (
        <div className="fixed bottom-24 right-6 w-80 md:w-96 bg-white rounded-2xl shadow-xl overflow-hidden z-50 flex flex-col max-h-[80vh] animate-fade-in">
          {/* Chat Header */}
          <div className="bg-primary-600 text-white p-4 flex items-center">
            <div className="w-10 h-10 bg-white rounded-full flex items-center justify-center mr-3">
              <span className="text-primary-600 font-bold">BW</span>
            </div>
            <div className="flex-1">
              <h3 className="font-medium">BookingWhizz Assistant</h3>
              <p className="text-xs opacity-80">Powered by AI</p>
            </div>
            <button
              onClick={toggleChat}
              className="text-white hover:text-gray-200"
              aria-label="Close chat"
            >
              <XIcon className="h-5 w-5" />
            </button>
          </div>

          {/* Chat Messages */}
          <div className="flex-1 p-4 overflow-y-auto bg-gray-50 max-h-[50vh]">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`flex mb-4 ${
                  message.sender === 'user' ? 'justify-end' : 'justify-start'
                }`}
              >
                <div
                  className={`rounded-lg p-3 max-w-[80%] ${
                    message.sender === 'user'
                      ? 'bg-primary-100 text-gray-800'
                      : 'bg-white shadow-sm text-gray-800'
                  }`}
                >
                  <p className="text-sm">{message.text}</p>
                  <p className="text-xs text-gray-500 text-right mt-1">
                    {message.time}
                  </p>
                </div>
              </div>
            ))}
          </div>

          {/* Suggested Questions */}
          <div className="p-3 border-t border-gray-200 bg-white">
            <p className="text-xs text-gray-500 mb-2">Suggested questions:</p>
            <div className="flex flex-wrap gap-2">
              {suggestedQuestions.map((question, index) => (
                <button
                  key={index}
                  onClick={() => handleSuggestedQuestion(question)}
                  className="text-xs bg-gray-100 hover:bg-gray-200 text-gray-800 px-3 py-1 rounded-full"
                >
                  {question}
                </button>
              ))}
            </div>
          </div>

          {/* Chat Input */}
          <form onSubmit={handleSendMessage} className="p-3 border-t flex items-center">
            <input
              type="text"
              value={inputText}
              onChange={handleInputChange}
              placeholder="Type your message..."
              className="flex-1 bg-gray-100 rounded-full px-4 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-primary-500"
            />
            <button
              type="submit"
              className="ml-2 bg-primary-600 text-white rounded-full p-2 hover:bg-primary-700"
              aria-label="Send message"
            >
              <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                />
              </svg>
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default ChatAssistant;
