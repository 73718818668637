import React from 'react';

const Card = ({ 
  title, 
  subtitle, 
  children, 
  className = '', 
  shadow = true, 
  hover = true,
  ...props 
}) => {
  const baseClasses = 'bg-white rounded-2xl p-6';
  const shadowClasses = shadow ? 'shadow-soft' : '';
  const hoverClasses = hover ? 'transition-all duration-300 hover:shadow-soft-lg hover:-translate-y-1' : '';
  
  return (
    <div className={`${baseClasses} ${shadowClasses} ${hoverClasses} ${className}`} {...props}>
      {title && <h3 className="text-xl font-semibold text-secondary-600 mb-2">{title}</h3>}
      {subtitle && <p className="text-tertiary-500 mb-4">{subtitle}</p>}
      {children}
    </div>
  );
};

export default Card;
