import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';

const navigation = [
  { name: 'Products', href: '#', dropdown: true, items: [
    { name: 'AI Website Builder', href: '/products/ai-website-builder' },
    { name: 'Booking Booster', href: '/products/booking-booster' },
    { name: 'CRM & Guest Journey', href: '/products/crm-guest-journey' },
    { name: 'Loyalty Program', href: '/products/loyalty-program' },
    { name: 'Upselling', href: '/products/upselling' },
    { name: 'Meta Distribution', href: '/products/meta-distribution' },
  ]},
  { name: 'Use Cases', href: '#', dropdown: true, items: [
    { name: 'Independent Hotels', href: '/use-cases/independent-hotels' },
    { name: 'Hotel Groups', href: '/use-cases/hotel-groups' },
    { name: 'Citywide Programs', href: '/use-cases/citywide-programs' },
  ]},
  { name: 'Why BookingWhizz', href: '/why-bookingwhizz' },
  { name: 'Case Studies', href: '/case-studies' },
  { name: 'Blog', href: '/blog' },
  { name: 'Integrations', href: '/integrations' },
];

const Header = () => {
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (index) => {
    if (openDropdown === index) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(index);
    }
  };

  return (
    <header className="bg-white shadow-soft sticky top-0 z-50">
      {/* Hotelier Questions Top Bar */}
      <div className="bg-primary-600 text-secondary-600 py-2 px-4 text-center text-sm">
        <div className="container flex justify-between items-center">
          <span className="hidden md:inline">Ask like a hotelier:</span>
          <div className="flex-1 flex justify-center space-x-4">
            <button className="hover:underline">How can I increase direct bookings?</button>
            <button className="hidden md:block hover:underline">What's the ROI of a loyalty program?</button>
            <button className="hidden lg:block hover:underline">How to personalize guest communications?</button>
          </div>
          <button className="hidden md:block text-xs border border-secondary-600 rounded-full px-2 py-1 hover:bg-primary-400">
            More Questions
          </button>
        </div>
      </div>

      {/* Main Navigation */}
      <div className="container">
        <Disclosure as="nav" className="py-3">
          {({ open }) => (
            <>
              <div className="flex justify-between items-center">
                {/* Logo */}
                <div className="flex-shrink-0">
                  <Link to="/" className="flex items-center">
                    <span className="text-2xl font-bold text-secondary-600">BookingWhizz</span>
                  </Link>
                </div>

                {/* Desktop Navigation */}
                <div className="hidden lg:flex lg:items-center">
                  <div className="flex space-x-6">
                    {navigation.map((item, index) => (
                      <div key={item.name} className="relative">
                        {item.dropdown ? (
                          <>
                            <button
                              onClick={() => toggleDropdown(index)}
                              className="text-secondary-600 hover:text-primary-500 px-3 py-2 rounded-md text-sm font-medium flex items-center"
                            >
                              {item.name}
                              <svg
                                className={`ml-1 h-4 w-4 transition-transform ${
                                  openDropdown === index ? 'rotate-180' : ''
                                }`}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                              </svg>
                            </button>
                            {openDropdown === index && (
                              <div className="absolute left-0 mt-2 w-56 rounded-2xl bg-white shadow-soft-lg py-2 z-10">
                                {item.items.map((subItem) => (
                                  <Link
                                    key={subItem.name}
                                    to={subItem.href}
                                    className="block px-4 py-2 text-sm text-secondary-600 hover:bg-primary-50 hover:text-primary-500"
                                    onClick={() => setOpenDropdown(null)}
                                  >
                                    {subItem.name}
                                  </Link>
                                ))}
                              </div>
                            )}
                          </>
                        ) : (
                          <Link
                            to={item.href}
                            className="text-secondary-600 hover:text-primary-500 px-3 py-2 rounded-md text-sm font-medium"
                          >
                            {item.name}
                          </Link>
                        )}
                      </div>
                    ))}
                  </div>
                </div>

                {/* CTA Button */}
                <div className="hidden lg:flex items-center">
                  <Link to="/contact" className="btn btn-primary">
                    Get a Demo
                  </Link>
                </div>

                {/* Mobile menu button */}
                <div className="flex items-center lg:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-secondary-600 hover:text-primary-500 hover:bg-primary-50">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>

              {/* Mobile Navigation */}
              <Disclosure.Panel className="lg:hidden">
                <div className="px-2 pt-2 pb-3 space-y-1">
                  {navigation.map((item) => (
                    <div key={item.name}>
                      {item.dropdown ? (
                        <Disclosure>
                          {({ open }) => (
                            <>
                              <Disclosure.Button className="w-full flex justify-between items-center px-3 py-2 text-base font-medium text-secondary-600 hover:bg-primary-50 hover:text-primary-500 rounded-md">
                                <span>{item.name}</span>
                                <svg
                                  className={`${open ? 'rotate-180' : ''} h-5 w-5 transition-transform`}
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                </svg>
                              </Disclosure.Button>
                              <Disclosure.Panel className="px-4 pt-2 pb-2 space-y-1">
                                {item.items.map((subItem) => (
                                  <Link
                                    key={subItem.name}
                                    to={subItem.href}
                                    className="block px-3 py-2 text-base font-medium text-tertiary-500 hover:bg-primary-50 hover:text-primary-500 rounded-md"
                                  >
                                    {subItem.name}
                                  </Link>
                                ))}
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      ) : (
                        <Link
                          to={item.href}
                          className="block px-3 py-2 text-base font-medium text-secondary-600 hover:bg-primary-50 hover:text-primary-500 rounded-md"
                        >
                          {item.name}
                        </Link>
                      )}
                    </div>
                  ))}
                  <Link
                    to="/contact"
                    className="block w-full text-center mt-4 btn btn-primary"
                  >
                    Get a Demo
                  </Link>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </header>
  );
};

export default Header;
